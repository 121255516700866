import { MANDRILL_TEMPLATES } from '@rabbit/bizproc/react';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { getConsumerURL, useAppInfo } from 'apps/sage/src/utils/helpers';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export interface QuoteClaimModalProps {}

interface FormValuesShape {
  comment_to_customer: string;
  cost_to_repair: {
    amount: number;
    currency: string;
  };
  internal_comment: string;
}

const validationSchema = Yup.object().shape({
  comment_to_customer: Yup.string()
    .trim()
    .test('remove-html-tags', 'please insert a message.', (value) => {
      const div = document.createElement('div');
      div.innerHTML = value || '';
      return !value || div.textContent?.trim() !== '';
    })
    .required('Please enter a message'),
  cost_to_repair: Yup.mixed().required('Please enter a repair cost'),
  internal_comment: Yup.string().trim(),
});

export function QuoteClaimModal({}: QuoteClaimModalProps) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);

  const initialValues = {
    comment_to_customer: '',
    cost_to_repair: {
      amount: 0,
      currency: appInfo.currency ?? '',
    },
    internal_comment: '',
  };

  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersona ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const onSubmit = async (values: FormValuesShape) => {
    const { comment_to_customer, internal_comment, cost_to_repair } = values;
    setIsSubmitting(true);

    await moveSpotlight(PersonaTypeSingleLetter.Consumer);
    executeAction('quote_customer');

    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
        comment_to_customer: comment_to_customer,
        cost_to_repair: cost_to_repair,
        cost_to_repair_accepted: false,
      };
      const to = caseFacts?.consumer_email ?? '',
        from = appInfo.email_sender,
        // template = MANDRILL_TEMPLATES.BODY_QUOTE,
        template = MANDRILL_TEMPLATES.BODY_QUOTE_V2,
        business_name = appInfo.name ?? '',
        claim_id = caseId,
        first_name = caseFacts?.consumer_name ?? '',
        //TODO Make FN to determine appropriate assessment
        assessment = caseFacts.final_assessment
          ? caseFacts.final_assessment
          : caseFacts.preliminary_assessment ?? '',
        product_name = caseFacts.consumer_holding_name ?? '',
        quote_currency = values.cost_to_repair.currency,
        quote_value = String(values?.cost_to_repair.amount),
        accept_quote_button = 'ACCEPT',
        decline_quote_button = 'DECLINE',
        subject =
          t('Your ') + business_name + t(' claim has an update - ') + product_name,
        link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;

      if (Object.keys(factsToAlter).length > 0) {
        await alterCaseFacts(factsToAlter);
        await alterCasePublicEmail({
          context: 'quote',
          from,
          to,
          subject,
          template,
          substitutions: {
            subject,
            business_name,
            claim_id,
            first_name,
            assessment,
            comment_to_customer,
            quote_currency,
            quote_value,
            accept_quote_button,
            decline_quote_button,
            product_name,
            link_to_claim,
          },
          shallBeSentViaBackend: true
        });
      }
      setShowModal(false);
      toast.success('Claim updated successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  };

  return (
    <Modal
      settings={{
        title: caseFacts?.cost_to_repair
          ? 'Requote customer for claim'
          : 'Quote customer for claim',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      kind="generic"
      isLoading={isSubmitting}
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, values }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <Input
              type="rich-text"
              label="Comment to customer*"
              name="comment_to_customer"
              settings={{
                id: 'comment_to_customer',
                allowSpecialCharacter: true,
                hint: '*required',
              }}
            />
            <Input
              type="currency"
              label="Estimated cost to repair/replacement"
              name="cost_to_repair"
              settings={{
                id: 'cost_to_repair',
                placeholder: '',
                currency: values?.cost_to_repair?.currency ?? appInfo.currency,
                hint: '*required',
              }}
            />
            {!showInternalComment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setShowInternalComment(true)}
                >
                  Add internal comment
                </Button>
              </div>
            )}
            {showInternalComment && (
              <div>
                <Input
                  type="rich-text"
                  label="Internal comment"
                  name="internal_comment"
                  settings={{
                    id: 'internal_comment',
                    placeholder: '',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            <div className="mt-8 flex gap-8">
              <Button kind="primary" type="submit" loading={isSubmitting}>
                Send quote
              </Button>
              <Button
                kind="outline_red"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default QuoteClaimModal;
