import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { MANDRILL_TEMPLATES } from '@rabbit/bizproc/react';
import { getConsumerURL, useAppInfo } from 'apps/sage/src/utils/helpers';
import { toast } from 'react-toastify';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';

export interface CompleteRepairModalProps {
  completeRepairLocal?: boolean;
}
interface FormValuesShape {
  comment_to_customer: string;
  internal_comment: string;
}

const initialValues = {
  comment_to_customer: '',
  internal_comment: '',
};

const validationSchema = Yup.object().shape({
  comment_to_customer: Yup.string()
    .trim()
    .test('remove-html-tags', 'please insert a message.', (value) => {
      const div = document.createElement('div');
      div.innerHTML = value || '';
      return !value || div.textContent?.trim() !== '';
    })
    .required('Please enter a comment'),
  internal_comment: Yup.string().trim(),
});

export function CompleteRepairModal({
  completeRepairLocal,
}: CompleteRepairModalProps) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);

  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    operatingPersonaSingleKey,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersona ||
    !operatingPersonaSingleKey ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const onSubmit = async (values: FormValuesShape) => {
    const { comment_to_customer, internal_comment } = values;

    setIsSubmitting(true);
    await moveSpotlight(PersonaTypeSingleLetter.Consumer);
    completeRepairLocal
      ? executeAction('complete_repair_local')
      : executeAction('complete_repair');

    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
        comment_to_customer: comment_to_customer,
      };
      const to = caseFacts?.consumer_email ?? '',
        from = appInfo.email_sender,
        // template = MANDRILL_TEMPLATES.BODY_AUTHORISED_REPAIR_COMPLETE,
        template = MANDRILL_TEMPLATES.BODY_AUTHORISED_REPAIR_COMPLETE_V2,
        business_name = appInfo.name ?? '',
        claim_id = caseId,
        first_name = caseFacts?.consumer_name ?? '',
        product_name = caseFacts.consumer_holding_name ?? '',
        subject = t('Your ') + product_name + t(' repair is complete'),
        link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;

      if (Object.keys(factsToAlter).length > 0) {
        await alterCaseFacts(factsToAlter);
        if (completeRepairLocal) {
          await alterCasePublicEmail({
            context: 'repair_complete',
            from,
            to,
            subject,
            template,
            substitutions: {
              subject,
              business_name,
              claim_id,
              first_name,
              product_name,
              comment_to_customer,
              link_to_claim,
            },
            shallBeSentViaBackend: true
          });
        }
      }
      setShowModal(false);
      toast.success('Claim updated successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  };

  return (
    <Modal
      settings={{
        title: completeRepairLocal ? 'Complete repair' : 'Contact customer',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      isLoading={isSubmitting}
      kind="generic"
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors }) => (
          <Form className="font-nunito mt-5 flex flex-col gap-3 px-4">
            <div className="mt-3 flex gap-2">
              <InformationCircleIcon className="h-5 w-5 text-gray-500" />
              <p className="font-nunito text-[14px] font-normal text-gray-500">
                Check all of the time spend and parts used have been logged for
                this repair before completing
              </p>
            </div>
            <div className="mt-3">
              <Input
                type="rich-text"
                label="Comments to customer*"
                name="comment_to_customer"
                settings={{
                  id: 'comment_to_customer',
                  placeholder: '',
                  allowSpecialCharacter: true,
                  hint: '*required',
                }}
              />
              {!showInternalComment && (
                <div className="mt-4">
                  <Button
                    kind="outline"
                    type="button"
                    className="w-full"
                    onClick={() => setShowInternalComment(true)}
                  >
                    Add internal comment
                  </Button>
                </div>
              )}
              {showInternalComment && (
                <div className="mt-4">
                  <Input
                    type="rich-text"
                    label="Internal comment"
                    name="internal_comment"
                    settings={{
                      id: 'internal_comment',
                      allowSpecialCharacter: true,
                    }}
                  />
                </div>
              )}
            </div>
            <div className="mt-8 flex gap-8">
              <Button kind="primary" type="submit" loading={isSubmitting}>
                {completeRepairLocal ? 'Complete repair' : 'Contact customer'}
              </Button>
              <Button
                kind="outline_red"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default CompleteRepairModal;
