import { Link, useNavigate } from 'react-router-dom';
import {
  CardWrapperWithHeader,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import ROUTE_NAME from '../../../utils/url-constants';
import { useContext } from 'react';
import { CaseflowContext } from '../../../context/CaseflowContext';
import Skeleton from 'react-loading-skeleton';
import { ConsumerHoldingSummaryShape } from '@rabbit/bizproc/client';
import { TenantLinkFieldName } from '@rabbit/data/types';
import { t } from 'i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProductDetailsCardProps {}

export function ProductDetailsCard(props: ProductDetailsCardProps) {
  const navigate = useNavigate();
  const context = useContext(CaseflowContext);
  const tenantLink = t(TenantLinkFieldName);
  // TODO: full_purchase_location isn't available on holding proxy so holdingData has to be used for now. needs to be added to holdingProxy
  // you can get this for srvs with retailerAddresses on the warranties array, not sure about non-SRVs. Investigate - dc
  const { holdingData, holdingProxyData } = context || {};

  //if (!context || !holdingData) return <LoadingSpinner size="xs" />;

  function getPurchaseLocation(
    holdingData: ConsumerHoldingSummaryShape
  ): string {
    let result = '-'; // Default value if no conditions are met
    const locationName = holdingData?.full_purchase_location?.name;
    const addresses = holdingData?.full_purchase_location?.addresses;
    const deprecatedAddress = holdingData?.full_purchase_location?.address;

    // Check the name and addresses array first
    if (locationName) {
      const town =
        addresses && addresses.length > 0 ? addresses[0]?.town || '' : '';
      if (town) {
        result = `${locationName} - ${town}`;
        return result;
      }
    }

    // Fallback to the deprecated address property
    if (locationName && deprecatedAddress?.town) {
      result = `${locationName} - ${deprecatedAddress.town}`;
      return result;
    }

    // Fallback to the other purchase location if no addresses or deprecated address
    if (holdingData?.purchase_location_other) {
      result = holdingData?.purchase_location_other;
      return result;
    }
    return result;
  }

  const productName: string =
    (holdingData?.brand ?? '') + ' ' + (holdingData?.title ?? '');

  const onProductDetailsClick = () => {
    navigate(ROUTE_NAME.PRODUCTS + '/' + holdingData?.vendableId, {
      state: {
        docid: holdingData?.vendableId,
        productName: holdingData?.full,
      },
    });
  };

  const isSRVTenant =
    tenantLink === 'NUCOVER' ||
    tenantLink === 'PINNACLEWARRANTIES' ||
    tenantLink === 'WARRANTYIRELAND';

  return (
    <CardWrapperWithHeader
      title={t('Product details')}
      headerRight={
        isSRVTenant ? null : (
          <ArrowTopRightOnSquareIcon
            className="text-primary-900 h-5 w-5 cursor-pointer"
            onClick={onProductDetailsClick}
          />
        )
      }
      subtitle={productName}
    >
      {!holdingData ? (
        <div className="font-nunito flex flex-col gap-3">
          <Skeleton count={2} containerClassName="max-w-[150px]" />
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="">
            <p className="font-nunito text-sm text-gray-500">
              {t('Purchase location')}
            </p>
            <p className="font-nunito mt-1 overflow-hidden text-ellipsis text-base">
              {getPurchaseLocation(holdingData)}
            </p>
          </div>
          {holdingProxyData?.serial && (
            <div className="mt-2.5">
              <p className="font-nunito mt-2.5 text-sm text-gray-500">
                {t('Product serial number')}
              </p>
              <p className="font-nunito mt-1 overflow-hidden text-ellipsis text-base">
                {holdingData.serial}
              </p>
            </div>
          )}
          {typeof holdingData?.purchase_price !== 'string' &&
            !!holdingData?.purchase_price?.amount && (
              <div className="mt-2.5">
                <p className="font-nunito mt-2.5 text-sm text-gray-500">
                  {t('Purchase price')}
                </p>
                <p className="font-nunito mt-2.5 overflow-hidden text-ellipsis text-base">
                  {getCurrencyFormat(
                    holdingData.purchase_price.amount,
                    holdingData.purchase_price.currency
                  )}
                </p>
              </div>
            )}
        </div>
      )}
    </CardWrapperWithHeader>
  );
}

export default ProductDetailsCard;
