import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import {
  MANDRILL_TEMPLATES,
  OptionShape,
  useGetRepairerPeers,
} from '@rabbit/bizproc/react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { getConsumerURL, useAppInfo } from 'apps/sage/src/utils/helpers';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export interface LocalRepairModalProps {}

interface FormValueShape {
  local_repairer: string;
  comment_to_customer: string;
  internal_comment: string;
}

const initialValues: FormValueShape = {
  local_repairer: '',
  comment_to_customer: '',
  internal_comment: '',
};

const validationSchema = Yup.object().shape({
  local_repairer: Yup.string().trim().required('Please select a technician'),
  comment_to_customer: Yup.string().trim(),
  internal_comment: Yup.string().trim(),
});

export function LocalRepairModal({}: LocalRepairModalProps) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { repairerPeer, repairerPeerLoading } = useGetRepairerPeers();
  const [showInternalComment, setShowInternalComment] = useState(false);

  const repairerUsers: OptionShape[] = repairerPeerLoading
    ? [
        {
          id: '-1',
          label: 'Loading...',
          value: 'Loading...',
        },
      ]
    : repairerPeer.map((repairer, index) => ({
        id: `${index}`,
        label: repairer?.name || '',
        value: repairer?.docid || '',
      }));
  const [usersForAssignment, setUsersForAssignment] = useState(
    [] as OptionShape[]
  );

  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    operatingPersonaSingleKey,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersona ||
    !operatingPersonaSingleKey ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  useEffect(() => {
    if (usersForAssignment.length === 0 || usersForAssignment[0]?.id === '-1')
      setUsersForAssignment(repairerUsers);
  }, [repairerUsers]);

  const onSubmit = async (values: FormValueShape) => {
    const { local_repairer, comment_to_customer, internal_comment } = values;

    setIsSubmitting(true);
    await moveSpotlight(operatingPersonaSingleKey);
    executeAction('assign_to_local_repairer');

    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
        local_repairer: local_repairer,
        comment_to_customer: comment_to_customer,
      };
      const to = caseFacts?.consumer_email ?? '',
        from = appInfo.email_sender,
        // template = MANDRILL_TEMPLATES.BODY_LOCAL_REPAIRER_ASSIGNED,
        template = MANDRILL_TEMPLATES.BODY_LOCAL_REPAIRER_ASSIGNED_V2,
        business_name = appInfo.name ?? '',
        claim_id = caseId,
        first_name = caseFacts?.consumer_name ?? '',
        product_name = caseFacts.consumer_holding_name ?? '',
        subject =
          t('Your claim has been assigned to an local repairer by ') +
          business_name +
          ' - ' +
          product_name,
        link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;

      if (Object.keys(factsToAlter).length > 0) {
        await alterCaseFacts(factsToAlter);
        await alterCasePublicEmail({
          context: 'local_repairer_assigned',
          to,
          from,
          subject,
          template,
          substitutions: {
            subject,
            business_name,
            claim_id,
            first_name,
            comment_to_customer,
            product_name,
            link_to_claim,
          },
          shallBeSentViaBackend: true
        });
      }
      setShowModal(false);
      toast.success('Claim updated successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  };

  return (
    <Modal
      settings={{
        title: 'Assign to internal technician',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      isLoading={isSubmitting}
      kind="generic"
      className="max-h-[768px] w-full max-w-[1024px]"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors }) => {
          return (
            <Form className="mt-5 flex flex-col px-4">
              <Input
                type="select"
                label="Assign to internal technician*"
                name="local_repairer"
                settings={{
                  options: usersForAssignment,
                  id: 'local_repairer',
                  placeholder: 'Please select an option',
                  hint: '*required',
                }}
              />
              <div className="mt-3">
                <Input
                  type="rich-text"
                  label="Comments to customer"
                  name="comment_to_customer"
                  settings={{ id: 'comment_to_customer' }}
                />
              </div>
              {!showInternalComment && (
                <div className="mt-4">
                  <Button
                    kind="outline"
                    type="button"
                    className="w-full"
                    onClick={() => setShowInternalComment(true)}
                  >
                    Add internal comment
                  </Button>
                </div>
              )}
              {showInternalComment && (
                <div className="mt-3">
                  <Input
                    type="rich-text"
                    label="Internal comment"
                    name="internal_comment"
                    settings={{
                      id: 'internal_comment',
                      placeholder: '',
                      allowSpecialCharacter: true,
                    }}
                  />
                </div>
              )}
              <div className="mt-8 flex gap-8">
                <Button kind="primary" type="submit" loading={isSubmitting}>
                  Assign technician
                </Button>
                <Button
                  kind="outline_red"
                  type="submit"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default LocalRepairModal;
