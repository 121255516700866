import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { updateHoldingOnClose } from 'apps/sage/src/utils/firestore-helpers';
import { toast } from 'react-toastify';
import { getConsumerURL, useAppInfo } from '@rabbit/sage/utils/helpers';
import { MANDRILL_TEMPLATES } from '@rabbit/bizproc/react';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';

export interface ConfirmItemReceivedModalProps {}

interface FormValuesShape {
  internal_comment: string;
}

const initialValues = {
  internal_comment: '',
};

const validationSchema = Yup.object().shape({
  internal_comment: Yup.string().trim(),
});

export function ConfirmItemReceivedModal({}: ConfirmItemReceivedModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const appInfo = useAppInfo();
  const { t } = useTranslation();

  const {
    caseFacts,
    alterCaseFacts,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    operatingPersonaSingleKey,
    caseId,
    alterCasePublicEmail,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !moveSpotlight ||
    !operatingPersona ||
    !operatingPersonaSingleKey ||
    !caseFacts ||
    !alterCasePublicEmail ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const onSubmit = async (values: FormValuesShape) => {
    const { internal_comment } = values;
    setIsSubmitting(true);
    await moveSpotlight(PersonaTypeSingleLetter.Consumer);
    executeAction('confirm_customer_received_item');

    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
      };
      if (Object.keys(factsToAlter).length > 0)
        await alterCaseFacts(factsToAlter);

      const to = caseFacts?.consumer_email ?? '',
        from = appInfo.email_sender,
        // template = MANDRILL_TEMPLATES.BODY_REPAIR_COMPLETE,
        template = MANDRILL_TEMPLATES.BODY_REPAIR_COMPLETE_V2,
        business_name = appInfo.name ?? '',
        claim_id = caseId,
        first_name = caseFacts?.consumer_name ?? '',
        product_name = caseFacts.consumer_holding_name ?? '',
        subject = business_name + t(' has closed your claim - ') + product_name,
        link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;
      await alterCasePublicEmail({
        context: 'generic_close_case',
        to,
        from,
        subject,
        template,
        substitutions: {
          subject,
          business_name,
          claim_id,
          first_name,
          comment_to_customer: '',
          product_name,
          link_to_claim,
        },
        shallBeSentViaBackend: true
      });
      await updateHoldingOnClose(caseId, caseFacts?.consumer_holding ?? '');
      setShowModal(false);
      toast.success('Claim updated successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  };

  return (
    <Modal
      settings={{
        title: 'Confirm customer received item',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      isLoading={isSubmitting}
      kind="generic"
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <div className="mt-4">
              <Input
                type="rich-text"
                name="internal_comment"
                label="Internal comment"
                settings={{
                  id: 'internal_comment',
                  placeholder: '',
                }}
              />
            </div>
            <div className="mt-8 flex gap-8">
              <Button kind="primary" type="submit" loading={isSubmitting}>
                Confirm and close case
              </Button>
              <Button
                kind="outline_red"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ConfirmItemReceivedModal;
