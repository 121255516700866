import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { getConsumerURL, useAppInfo } from 'apps/sage/src/utils/helpers';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { MANDRILL_TEMPLATES } from '@rabbit/bizproc/react';
import { updateHoldingOnClose } from 'apps/sage/src/utils/firestore-helpers';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';

export interface RejectClaimModalProps {}

interface FormValueShape {
  comment_to_customer: string;
  internal_comment: string;
}

const initialValues: FormValueShape = {
  comment_to_customer: '',
  internal_comment: '',
};

const validationSchema = Yup.object().shape({
  comment_to_customer: Yup.string()
    .trim()
    .required("Comments to customer can't be empty")
    .test(
      'remove-html-tags',
      "Comments to customer can't be empty",
      (value) => {
        const div = document.createElement('div');
        div.innerHTML = value || '';
        return !value || div.textContent?.trim() !== '';
      }
    ),
  internal_comment: Yup.string().trim(),
});

export function RejectClaimModal({}: RejectClaimModalProps) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);

  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersona ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const onSubmit = async (values: FormValueShape) => {
    setIsSubmitting(true);

    await moveSpotlight(PersonaTypeSingleLetter.Consumer);
    executeAction('reject_claim');
    const to = caseFacts?.consumer_email ?? '',
      from = appInfo.email_sender,
      // template = MANDRILL_TEMPLATES.BODY_CLAIM_REJECTED,
      template = MANDRILL_TEMPLATES.BODY_CLAIM_REJECTED_V2,
      business_name = appInfo.name ?? '',
      claim_id = caseId,
      first_name = caseFacts?.consumer_name ?? '',
      product_name = caseFacts.consumer_holding_name ?? '',
      preliminary_assessment = caseFacts?.preliminary_assessment ?? '',
      comment_to_customer = values.comment_to_customer,
      subject = t('Your ') + product_name + t(' claim has been rejected'),
      link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;

    if (values.internal_comment) {
      await alterCaseFacts({
        comment_to_customer: values.comment_to_customer,
        internal_comment: {
          comment: values.internal_comment,
          author: operatingPersona,
        },
      });
      await alterCasePublicEmail({
        context: 'claim_rejected',
        from,
        to,
        subject,
        template,
        substitutions: {
          subject,
          business_name,
          claim_id,
          first_name,
          preliminary_assessment,
          comment_to_customer,
          product_name,
          link_to_claim,
        },
        shallBeSentViaBackend: true
      });
    } else {
      await alterCaseFacts({
        comment_to_customer: values.comment_to_customer,
      });
      await alterCasePublicEmail({
        context: 'claim_rejected',
        from,
        to,
        subject,
        template,
        substitutions: {
          subject,
          business_name,
          claim_id,
          first_name,
          preliminary_assessment,
          comment_to_customer,
          product_name,
          link_to_claim,
        },
        shallBeSentViaBackend: true
      });
    }
    await updateHoldingOnClose(caseId, caseFacts.consumer_holding ?? '');
    setShowModal(false);
    toast.success('Claim updated successfully.');
  };

  return (
    <Modal
      settings={{
        title: 'Reject claim and close',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      isLoading={isSubmitting}
      kind="generic"
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors }) => {
          return (
            <Form className="mt-5 flex flex-col px-4">
              <Input
                type="rich-text"
                label="Comments to customer*"
                name="comment_to_customer"
                settings={{
                  id: 'comment_to_customer',
                  hint: '*required',
                }}
              />
              {!showInternalComment && (
                <div className="mt-4">
                  <Button
                    kind="outline"
                    type="button"
                    className="w-full"
                    onClick={() => setShowInternalComment(true)}
                  >
                    Add internal comment
                  </Button>
                </div>
              )}
              {showInternalComment && (
                <div className="mt-3">
                  <Input
                    type="rich-text"
                    label="Internal comment"
                    name="internal_comment"
                    settings={{
                      id: 'internal_comment',
                      placeholder: '',
                      allowSpecialCharacter: true,
                    }}
                  />
                </div>
              )}
              <div className="mt-8 flex gap-8">
                <Button kind="primary" type="submit" loading={isSubmitting}>
                  Notify customer
                </Button>
                <Button
                  kind="outline_red"
                  type="submit"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default RejectClaimModal;
