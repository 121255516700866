import { Form, Formik } from 'formik';
import {
  Button,
  ButtonIcon,
  Input,
  LoadingSpinner,
  Modal,
  Toggle,
} from '@rabbit/elements/shared-components';
import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  COVERAGE_GOODWILL_NONE_OPTIONS,
  COVERAGE_GOODWILL_PARTIAL_OPTIONS,
  COVERAGE_FINAL_OPTIONS,
  useManageFaults,
  useGetRepairerPeers,
  OptionShape,
  MANDRILL_TEMPLATES,
} from '@rabbit/bizproc/react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { toast } from 'react-toastify';
import {
  generateFaultOptions,
  getConsumerURL,
  useAppInfo,
} from '@rabbit/sage/utils/helpers';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

export interface FinalAssessmentModalProps {}

interface FormValuesShape {
  internal_comment: string;
  goodwill_warranty_final: string;
  comment_to_customer: string;
  holding_faults: string[] | null;
  time_spent_assessing: {
    user: string;
    time: string;
  }[];
  final_assessment: string;
}

const validationSchema = Yup.object().shape({
  final_assessment: Yup.string().trim().required('Please select an option'),
  goodwill_warranty_final: Yup.string().trim(),
  holding_faults: Yup.array()
    .min(1, 'Please select one or more faults')
    .of(Yup.string().trim())
    .required('Please select one or more faults'),
  time_spent_assessing: Yup.array()
    .of(
      Yup.object().shape({
        user: Yup.string().trim(),
        time: Yup.string().trim(),
      })
    )
    .compact((v) => !v.user || !v.time),
  comment_to_customer: Yup.string()
    .trim()
    .required('Please enter a message')
    .test('remove-html-tags', 'please insert a message.', (value) => {
      const div = document.createElement('div');
      div.innerHTML = value || '';
      return !value || div.textContent?.trim() !== '';
    }),
  internal_comment: Yup.string().trim(),
});

export function FinalAssessmentModal({}: FinalAssessmentModalProps) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const [goodwillOverride, setGoodwillOverride] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);
  let [timeAssessingCount, setTimeAssessingCount] = useState(0);
  const { faultList } = useManageFaults();
  const faultOptions = generateFaultOptions(faultList?.data);
  const { repairerPeer, repairerPeerLoading } = useGetRepairerPeers();

  const repairerUsers: OptionShape[] = repairerPeerLoading
    ? [
        {
          id: '-1',
          label: 'Loading...',
          value: 'Loading...',
        },
      ]
    : repairerPeer.map((repairer, index) => ({
        id: `${index}`,
        label: repairer?.name || '',
        value: repairer?.docid || '',
      }));
  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    operatingPersonaSingleKey,
    caseId,
  } = useContext(CaseflowContext) || {};

  const initialValues: FormValuesShape = {
    internal_comment: '',
    goodwill_warranty_final: '',
    comment_to_customer: '',
    holding_faults: caseFacts?.holding_faults ?? null,
    time_spent_assessing: [],
    final_assessment: '',
  };

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersona ||
    !operatingPersonaSingleKey ||
    !caseFacts ||
    !faultList?.data ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const { preliminary_assessment } = caseFacts;

  const onSubmit = async (values: FormValuesShape) => {
    const {
      internal_comment,
      goodwill_warranty_final,
      comment_to_customer,
      time_spent_assessing,
      final_assessment,
      holding_faults,
    } = values;

    setIsSubmitting(true);
    console.log(values);

    await moveSpotlight(operatingPersonaSingleKey);
    executeAction('final_assessment', {
      assessment: final_assessment,
    });

    time_spent_assessing.forEach((timeSpent) => {
      timeSpent.user =
        repairerUsers.find((user) => timeSpent.user === user.value)?.label ||
        '';
    });
    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
        holding_faults: holding_faults ?? [],
        goodwill_override_final: goodwillOverride,
        goodwill_warranty_final: goodwill_warranty_final,
        time_spent_assessing: time_spent_assessing,
        comment_to_customer: comment_to_customer,
      };
      const to = caseFacts?.consumer_email ?? '',
        from = appInfo.email_sender,
        // template = MANDRILL_TEMPLATES.BODY_ASSESSMENTS_DIFFER,
        template = MANDRILL_TEMPLATES.BODY_ASSESSMENTS_DIFFER_V2,
        business_name = appInfo.name ?? '',
        claim_id = caseId,
        first_name = caseFacts?.consumer_name ?? '',
        product_name = caseFacts.consumer_holding_name ?? '',
        preliminary_assessment = caseFacts?.preliminary_assessment ?? '',
        final_assessment = values.final_assessment,
        subject =
          business_name +
          t(' has updated your claim assessment - ') +
          product_name,
        link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;
      if (Object.keys(factsToAlter).length > 0) {
        await alterCaseFacts(factsToAlter);
        await alterCasePublicEmail({
          context: 'assessments_differ',
          from,
          to,
          subject,
          template,
          substitutions: {
            subject,
            business_name,
            claim_id,
            first_name,
            preliminary_assessment,
            final_assessment,
            comment_to_customer,
            link_to_claim,
            product_name,
          },
          shallBeSentViaBackend: true
        });
      }
      setShowModal(false);
      toast.success('Claim updated successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  };

  return (
    <Modal
      settings={{
        title: 'Final assessment',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      kind="generic"
      isLoading={isSubmitting}
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, values, setFieldValue }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <Input
              type="text"
              name="warr_assessment"
              label="Initial coverage assessment"
              settings={{
                id: 'warr_assessment',
                placeholder: preliminary_assessment,
                disabled: true,
              }}
            />
            <Input
              type="select"
              name="final_assessment"
              label="Final coverage assessment*"
              settings={{
                id: 'final_assessment',
                placeholder: 'Coverage',
                options: COVERAGE_FINAL_OPTIONS,
                errors: errors,
                hint: '*required',
              }}
            />
            {(preliminary_assessment === 'Partially covered' ||
              preliminary_assessment === 'Not covered') && (
              <Toggle
                checked={false}
                onChange={() => setGoodwillOverride(!goodwillOverride)}
                label="Goodwill override"
                //disabled={values.warr_assessment === 'Full' || values.warr_assessment ===  'Unknown'}
              />
            )}
            {goodwillOverride && (
              <Input
                type="select"
                name="goodwill_warranty_final"
                label="Goodwill warranty assessment*"
                settings={{
                  id: 'goodwill_warranty_final',
                  placeholder: 'Coverage',
                  options:
                    preliminary_assessment === 'Partially covered'
                      ? COVERAGE_GOODWILL_PARTIAL_OPTIONS
                      : COVERAGE_GOODWILL_NONE_OPTIONS,
                  errors: errors,
                  hint: '*required',
                }}
              />
            )}
            <Input
              type="select"
              name="holding_faults"
              label="Faults*"
              settings={{
                id: 'holding_faults',
                options: faultOptions,
                isMulti: true,
                placeholder: faultList?.data ? 'Select...' : 'Loading...',
                disabled: !faultList?.data,
                hint: '*required',
              }}
            />

            {Array(timeAssessingCount)
              .fill(0)
              .map((_, index) => (
                <div
                  className="grid grid-cols-[1fr_1fr_40px] gap-4"
                  key={index}
                >
                  <Input
                    type="select"
                    name={'time_spent_assessing[' + index + '].user'}
                    settings={{
                      options: repairerUsers,
                      id: 'time_spent_assessing[' + index + '].user',
                      placeholder: 'Please select a user',
                    }}
                  />
                  <Input
                    type="time"
                    name={'time_spent_assessing[' + index + '].time'}
                    settings={{
                      id: 'time_spent_assessing[' + index + '].time',
                    }}
                  />
                  <ButtonIcon
                    Icon={TrashIcon}
                    label=""
                    onClick={() => {
                      setTimeAssessingCount(--timeAssessingCount);
                      setFieldValue(
                        'time_spent_assessing',
                        values.time_spent_assessing.filter(
                          (_, i) => i !== index
                        )
                      );
                    }}
                  />
                </div>
              ))}
            <div className="ml-0 mr-auto">
              <ButtonIcon
                iconLeft={false}
                label="Log time"
                onClick={() => {
                  setTimeAssessingCount(++timeAssessingCount);
                }}
              />
            </div>
            <Input
              type="rich-text"
              name="comment_to_customer"
              label="Comments to customer*"
              settings={{
                id: 'comment_to_customer',
                allowSpecialCharacter: true,
                hint: '*required',
              }}
            />
            {!showInternalComment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setShowInternalComment(true)}
                >
                  Add internal comment
                </Button>
              </div>
            )}
            {showInternalComment && (
              <div>
                <Input
                  type="rich-text"
                  label="Internal comment"
                  name="internal_comment"
                  settings={{
                    id: 'internal_comment',
                    placeholder: '',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            <div className="mt-8 flex gap-8">
              <Button kind="primary" type="submit" loading={isSubmitting}>
                Continue
              </Button>
              <Button
                kind="outline_red"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default FinalAssessmentModal;
